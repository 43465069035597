class DaysHelper {
  public static getAllDays(): string[] {
    return [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ];
  }

  public static getDayByIndex(index: number): string {
    return this.getAllDays()[index];
  }

  public static getOrderDays(): string[] {
    return [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ];
  }
}

export default DaysHelper;
