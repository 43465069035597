import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "break-words bg-gray-500 rounded-lg text-xl text-center text-white p-1 mb-3" }
const _hoisted_2 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnimeCard = _resolveComponent("AnimeCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-3 rounded-lg", { 'bg-blue-200': _ctx.day === _ctx.getDayOfWeek() }])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(this.day), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.animeList, (anime) => {
          return (_openBlock(), _createBlock(_component_AnimeCard, {
            key: anime.myanimelist_id,
            anime: anime,
            type: "agenda",
            class: "mb-4"
          }, null, 8, ["anime"]))
        }), 128))
      ])
    ])
  ], 2))
}