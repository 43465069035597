
import DraggableWindow from "@/components/draggable-window/DraggableWindow.vue";
import { defineComponent } from "vue";
import Anime from "@/class/Anime";
import StatusMyanimelist from "@/helper/StatusMyanimelist";
import lodash from "lodash";

export default defineComponent({
  name: "AnimeEditWindows",
  components: { DraggableWindow },
  data() {
    return {
      editingEpisode: false,
      tagSelected: -1,
      tempValue: 0,
      isAnimeDetail: true,
      selectedStatus: this.anime?.getUserAnime()?.getStatus(),
      tags: this.anime.getUserAnime()?.getTags() ?? [],
      oldStatus: "watching",
      animeLink: this.anime.getUserAnime()?.getLinkEpisode() ?? "",
    };
  },
  props: {
    anime: {
      type: Anime,
      required: true,
    },
  },
  computed: {
    offsetWindowLeft() {
      return window.innerWidth / 2 - this.windowsWidth / 2;
    },
    offsetWindowsTop() {
      return window.innerHeight / 2 - this.windowsHeight / 2;
    },
    windowsWidth() {
      return (window.innerWidth / 1885) * 800;
    },
    windowsHeight() {
      return (window.innerHeight / 969) * 485;
    },
    getStatusAnime() {
      return StatusMyanimelist.getAllUserStatus();
    },
  },
  methods: {
    updateAnime() {
      this.anime.user_animes?.save(this.oldStatus);
    },
    closeWindow() {
      this.updateAnime();
      this.isAnimeDetail = false;
      this.$emit("closeWindow");
    },
    setStatus() {
      if (!this.selectedStatus) return;
      this.anime.user_animes?.setStatus(this.selectedStatus);
    },
    editEpisode() {
      if (this.anime.user_animes === undefined) return;

      this.editingEpisode = true;
      this.tempValue = this.anime.user_animes.last_episode_watch;
      this.$nextTick(() => {
        const inputElement = this.$refs.input as HTMLInputElement;
        inputElement.focus();
      });
    },
    saveEpisode() {
      this.anime.setLastEpisodeWatch(this.tempValue);
      this.editingEpisode = false;
    },
    addTag() {
      this.tags.push("");
      this.tagSelected = this.tags.length - 1;

      this.$nextTick(() => {
        const newTagInput = this.$refs[
          `tag-input-${this.tagSelected}`
        ] as Array<HTMLInputElement>;
        if (newTagInput && newTagInput[0]) {
          newTagInput[0].focus();
        }
      });
    },
    editTags(index: number) {
      this.tagSelected = index;
      this.$nextTick(() => {
        const newTagInput = this.$refs[
          `tag-input-${index}`
        ] as Array<HTMLInputElement>;
        if (newTagInput && newTagInput[0]) {
          newTagInput[0].focus();
        }
      });
    },
    saveTag(index: number) {
      if (this.tags[index] === "") {
        this.tags.splice(index, 1);
      }

      this.tagSelected = -1;
      this.anime.getUserAnime()?.setTags(this.tags);
    },
    deleteTag(index: number) {
      this.tags.splice(index, 1);
      this.saveTag(index);
    },
    deleteAnimeList() {
      this.anime.user_animes?.delete();
    },
    setLinkEpisode() {
      this.anime?.getUserAnime()?.setEpisodeLink(this.animeLink);
    },
  },
  mounted() {
    if (this.anime.user_animes === undefined) return;
    this.oldStatus = lodash.cloneDeep(this.anime.user_animes.getStatus());
  },
});
