import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Agenda from "../views/Anime/AgendaView.vue";
import AnimeList from "../views/Anime/AnimeListView.vue";
import Login from "@/views/Login/LoginView.vue";
import Register from "@/views/Login/RegisterView.vue";
import Profile from "@/views/Profile/ProfileView.vue";
import RedirectToken from "@/views/Login/RedirectToken.vue";
import DashBord from "@/views/Admin/DashBordView.vue";
import HomePage from "@/views/HomePage.vue";
import UserAuthManager from "@/class/login/UserAuthManager";
import UserAuthStore from "@/store/UserAuthStore";
import Notiflix from "notiflix";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    alias: ["/login"],
    component: Login,
    meta: {
      hideNavbar: true,
    },
    beforeEnter(to, from, next) {
      return next();
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
    meta: {
      hideNavbar: false,
    },
  },
  {
    path: "/agenda",
    name: "agenda",
    component: Agenda,
    meta: {
      hideNavbar: false,
    },
  },
  {
    path: "/anime-list",
    name: "anime-list",
    component: AnimeList,
    meta: {
      hideNavbar: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      hideNavbar: false,
    },
  },
  {
    path: "/redirect-token",
    name: "redirect-token",
    component: RedirectToken,
  },
  {
    path: "/admin",
    name: "admin",
    component: DashBord,
  },
  {
    path: "/logout",
    name: "logout",
    redirect: () => {
      UserAuthManager.handleLogout();
      return { name: "login" };
    },
  },
  {
    path: "/:catchAll(.*)",
    component: Agenda,
    meta: {
      hideNavbar: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// From the doc, make sure next() is called once .
router.beforeEach((to, from, next) => {
  const userAuthStore = UserAuthStore();
  if (
    to.name !== "login" &&
    to.name !== "register" &&
    userAuthStore.user === undefined
  ) {
    Notiflix.Notify.warning(
      "Vous devez être connecté pour accèder à cette page."
    );
    next({ name: "login" });
  } else {
    next();
  }
});

// Save the last route to the local storage
router.beforeEach((to, from, next) => {
  if (UserAuthManager.isLoggedIn() && to.name !== "login") {
    localStorage.setItem(
      "lastRouteKumagenda",
      typeof to.name === "string" ? to.name : "login"
    );
  }
  next();
});

export default router;
