import { defineStore } from "pinia";
import Anime from "@/class/Anime";
import MainEndpoint from "@/endpoints/main";
import FilterAnime from "@/class/FilterAnime";
import { UnwrapRef } from "vue";

const AnimeStore = defineStore("animes", {
  state: () => ({
    watchingAnimeList: [] as Anime[],
    completedAnimeList: [] as Anime[],
    onHoldAnimeList: [] as Anime[],
    droppedAnimeList: [] as Anime[],
    planToWatchAnimeList: [] as Anime[],
    animeAdmin: [] as Anime[],
    filter: new FilterAnime(),
  }),
  getters: {
    getFilteredAnimeList(): UnwrapRef<Anime[]> {
      let filteredList = this.watchingAnimeList;

      switch (this.filter.getStatusQuery()) {
        case "completed":
          filteredList = this.completedAnimeList;
          break;
        case "on_hold":
          filteredList = this.onHoldAnimeList;
          break;
        case "dropped":
          filteredList = this.droppedAnimeList;
          break;
        case "plan_to_watch":
          filteredList = this.planToWatchAnimeList;
          break;
      }

      if (!this.filter.getIsOnReleaseAllow()) {
        filteredList = filteredList.filter(
          (anime) => anime.status !== "currently_airing"
        );
      }

      if (this.filter.getIsStartWatching()) {
        filteredList = filteredList.filter((anime) => {
          if (anime.user_animes === undefined) {
            return false;
          }
          return anime.user_animes.last_episode_watch > 0;
        });
      }

      if (this.filter.getSearchQuery().trim() !== "") {
        filteredList = filteredList.filter((anime) =>
          anime.title
            .toLowerCase()
            .includes(this.filter.getSearchQuery().toLowerCase())
        );
      }

      if (this.filter.getTagsQuery().length > 0) {
        filteredList = filteredList.filter((anime) => {
          const animeTags = anime.user_animes?.tag.split(",");
          return this.filter
            .getTagsQuery()
            .some((tag) => animeTags?.includes(tag));
        });
      }

      return filteredList;
    },
    getAnimeAdmin(): UnwrapRef<Anime[]> {
      let filteredList = this.animeAdmin;

      if (!this.filter.getIsOnReleaseAllow()) {
        filteredList = filteredList.filter(
          (anime) => anime.status === "currently_airing"
        );
      }

      if (this.filter.getSearchQuery().trim() !== "") {
        filteredList = filteredList.filter((anime) =>
          anime.title
            .toLowerCase()
            .includes(this.filter.getSearchQuery().toLowerCase())
        );
      }

      return filteredList;
    },
    getWatchingAnime(): UnwrapRef<Anime[]> {
      return this.watchingAnimeList;
    },
    getAnimeAganda(): UnwrapRef<Anime[]> {
      return this.watchingAnimeList.filter((anime) => anime.agenda !== null);
    },
    getFilter(): UnwrapRef<FilterAnime> {
      return this.filter;
    },
  },
  actions: {
    initAnime() {
      this.initAnimeWatching();
      this.initAnimeCompleted();
      this.initAnimeOnHold();
      this.initAnimeDropped();
      this.initAnimePlanToWatch();
      this.initAnimeAdmin();
    },
    initAnimeWatching() {
      MainEndpoint.getAxiosInstance()
        .get("/anime/user/list/watching")
        .then((response) => {
          this.watchingAnimeList = [];

          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.watchingAnimeList.push(newAnime);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initAnimeCompleted() {
      MainEndpoint.getAxiosInstance()
        .get(`/anime/user/list/completed`)
        .then((response) => {
          this.completedAnimeList = [];

          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.completedAnimeList.push(newAnime);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initAnimeOnHold() {
      MainEndpoint.getAxiosInstance()
        .get("/anime/user/list/on_hold")
        .then((response) => {
          this.onHoldAnimeList = [];
          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.onHoldAnimeList.push(newAnime);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initAnimeDropped() {
      MainEndpoint.getAxiosInstance()
        .get("/anime/user/list/dropped")
        .then((response) => {
          this.droppedAnimeList = [];
          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.droppedAnimeList.push(newAnime);
          });
        });
    },
    initAnimePlanToWatch() {
      MainEndpoint.getAxiosInstance()
        .get("/anime/user/list/plan_to_watch")
        .then((response) => {
          this.planToWatchAnimeList = [];
          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.planToWatchAnimeList.push(newAnime);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initAnimeAdmin() {
      MainEndpoint.getAxiosInstance()
        .get("/admin/agenda")
        .then((response) => {
          this.animeAdmin = [];
          response.data.forEach((anime: unknown) => {
            const newAnime = new Anime();
            newAnime.fillFromJSON(anime);
            this.animeAdmin.push(newAnime);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    refreshAnimeList() {
      MainEndpoint.getAxiosInstance()
        .post("/anime/user/refresh-agenda")
        .then(() => {
          this.initAnime();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearStore() {
      this.watchingAnimeList = [];
      this.completedAnimeList = [];
      this.onHoldAnimeList = [];
      this.droppedAnimeList = [];
      this.planToWatchAnimeList = [];
      this.animeAdmin = [];
      this.filter = new FilterAnime();
    },
    changeStatusAnime(anime: Anime, fromStatus: string, toStatus: string) {
      this.addAnimeToList(anime, toStatus);
      this.removeAnimeFromList(anime, fromStatus);
    },
    addAnimeToList(anime: Anime, status: string) {
      switch (status) {
        case "watching":
          this.watchingAnimeList.push(anime);
          break;
        case "completed":
          this.completedAnimeList.push(anime);
          break;
        case "on_hold":
          this.onHoldAnimeList.push(anime);
          break;
        case "dropped":
          this.droppedAnimeList.push(anime);
          break;
        case "plan_to_watch":
          this.planToWatchAnimeList.push(anime);
          break;
      }
    },
    removeAnimeFromList(anime: Anime, status: string) {
      switch (status) {
        case "watching":
          this.watchingAnimeList = this.watchingAnimeList.filter(
            (a) => a !== anime
          );
          break;
        case "completed":
          this.completedAnimeList = this.completedAnimeList.filter(
            (a) => a !== anime
          );
          break;
        case "on_hold":
          this.onHoldAnimeList = this.onHoldAnimeList.filter(
            (a) => a !== anime
          );
          break;
        case "dropped":
          this.droppedAnimeList = this.droppedAnimeList.filter(
            (a) => a !== anime
          );
          break;
        case "plan_to_watch":
          this.planToWatchAnimeList = this.planToWatchAnimeList.filter(
            (a) => a !== anime
          );
          break;
      }
    },
  },
});

export default AnimeStore;
