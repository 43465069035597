class StatusMyanimelist {
  static getAnimeStatus(status: string) {
    switch (status) {
      case "1":
        return "Airing";
      case "2":
        return "Finished Airing";
      case "3":
        return "Not yet aired";
      case "4":
        return "Cancelled";
      case "5":
        return "Not yet aired";
      case "6":
      default:
        return "Not yet aired";
    }
  }

  static getUserStatus(status: number) {
    switch (status) {
      case 1:
        return "Watching";
      case 2:
        return "Completed";
      case 3:
        return "On Hold";
      case 4:
        return "Dropped";
      case 6:
      default:
        return "Plan to Watch";
    }
  }

  static getAllUserStatus() {
    return [
      { status: "Watching", value: "watching" },
      { status: "Completed", value: "completed" },
      { status: "On hold", value: "on_hold" },
      { status: "Dropped", value: "dropped" },
      { status: "Plan to watch", value: "plan_to_watch" },
    ];
  }
}

export default StatusMyanimelist;
