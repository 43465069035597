import Serializable from "@/helper/SerializationHelper";
import Anime from "@/class/Anime";
import MainEndpoint from "@/endpoints/main";
import Notiflix, { Notify } from "notiflix";
import AnimeStore from "@/store/AnimeStore";
import draggableWindowsShowBus from "@/bus/draggableWindowsShowBus";

class User_anime extends Serializable {
  public last_episode_watch = 0;

  public score = 0;

  public nb_rewatching = 0;

  public is_rewatching = false;

  public tag = "";

  public status = "";

  public anime = undefined as Anime | undefined;

  public link_episode = "";

  constructor(anime: Anime) {
    super();
    this.anime = anime;
  }

  // eslint-disable-next-line
  fillFromJSON(userAnimeJson: any) {
    super.fillFromJSON(userAnimeJson);
    this.last_episode_watch = userAnimeJson.last_episode_watch as number;
    this.score = userAnimeJson.score;
    this.nb_rewatching = userAnimeJson.nb_rewatching;
    this.is_rewatching = userAnimeJson.is_rewatching;
    this.tag = userAnimeJson.tag;
    this.status = userAnimeJson.status;
    this.link_episode = userAnimeJson.link_episode;
  }

  public addEpisode() {
    if (this.anime === undefined) return;

    if (
      this.status !== "watching" &&
      this.last_episode_watch + 1 >= this.anime.total_episode
    ) {
      return;
    }

    this.last_episode_watch = Number(this.last_episode_watch) + 1;
    this.updateEpisode();
  }

  public getLinkEpisode() {
    return this.link_episode;
  }

  public setEpisodeLink(link: string) {
    this.link_episode = link;
  }

  public getStatus() {
    return this.status;
  }

  public setStatus(status: string) {
    this.status = status;
  }

  public setTags(tags: Array<string>) {
    this.tag = tags.join(",");
  }

  public getTags() {
    if (this.tag === "") return [];
    return this.tag.split(",");
  }

  public updateEpisode() {
    if (this.anime === undefined) return;

    const clb = () => {
      const data = {
        anime_myanimelist_id: this.anime?.myanimelist_id,
        last_episode_watch: this.last_episode_watch,
      };

      MainEndpoint.getAxiosInstance()
        .post("/anime/user/episode/update", data)
        .catch((error) => {
          console.log(error);
        });
    };

    if (
      this.anime.total_episode !== 0 &&
      this.last_episode_watch >= this.anime.total_episode &&
      this.status == "watching"
    ) {
      this.askComplateAnime(clb);
    } else {
      clb();
    }
  }

  public save(oldStatus: string) {
    if (this.anime === undefined) return;

    if (
      this.anime.total_episode !== 0 &&
      this.status !== "watching" &&
      this.last_episode_watch + 1 >= this.anime.total_episode
    ) {
      this.last_episode_watch = this.anime.total_episode;
    }

    const clb = () => {
      const data = {
        anime_myanimelist_id: this.anime?.myanimelist_id,
        score: this.score,
        status: this.status,
        last_episode_watch: this.last_episode_watch,
        nb_rewatching: this.nb_rewatching,
        is_rewatching: this.is_rewatching,
        tags: this.tag,
        link_episode: this.link_episode,
        // watchWith: "", // TODO: Add watchWith
      };

      MainEndpoint.getAxiosInstance()
        .post(`/anime/user/update`, data)
        .then(() => {
          if (this.anime === undefined) return;

          if (this.status !== oldStatus) {
            const animeStore = AnimeStore();
            animeStore.changeStatusAnime(this.anime, oldStatus, this.status);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (
      this.last_episode_watch >= this.anime.total_episode &&
      this.status == "watching" &&
      this.last_episode_watch !== 0 &&
      this.anime.total_episode !== 0
    ) {
      this.askComplateAnime(clb);
    } else {
      clb();
    }
  }

  public complete() {
    if (this.anime === undefined) return;

    const data = {
      anime_myanimelist_id: this.anime.myanimelist_id,
      score: this.score,
      nb_rewatching: this.nb_rewatching,
      is_rewatching: this.is_rewatching,
      tag: this.tag,
      total_episode: this.anime.total_episode,
    };

    MainEndpoint.getAxiosInstance()
      .post(`/anime/user/complete`, data)
      .then(() => {
        if (this.anime === undefined) return;

        const animeStore = AnimeStore();
        animeStore.changeStatusAnime(this.anime, this.status, "completed");
        Notify.success("Anime completer avec succes");
      })
      .catch((error) => {
        console.log(error);
        Notify.failure("Probleme pour completer l'anime");
      });
  }

  public askComplateAnime(clb: () => void) {
    draggableWindowsShowBus.trigger("close", false);
    Notiflix.Confirm.prompt(
      "Anime complet ?",
      "Voulez vous marquer l'anime comme complet ? Score?",
      this.score.toString(),
      "Oui",
      "Non",
      (clientAnswer) => {
        const newScore = Number(clientAnswer);
        if (newScore >= 0 && newScore <= 10) {
          this.score = newScore;
        } else if (newScore > 10) {
          this.score = 10;
        } else {
          this.score = 0;
        }
        this.complete();
      },
      () => {
        clb;
        draggableWindowsShowBus.trigger("open", true);
      }
    );
  }

  public delete() {
    if (this.anime === undefined) return;

    const data = {
      anime_myanimelist_id: this.anime.myanimelist_id,
    };

    MainEndpoint.getAxiosInstance()
      .post(`/anime/user/delete`, data)
      .then(() => {
        if (this.anime === undefined) return;

        const animeStore = AnimeStore();
        animeStore.removeAnimeFromList(this.anime, this.status);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default User_anime;
