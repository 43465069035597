
import { defineComponent } from "vue";
import Anime from "@/class/Anime";
import AnimeEdit from "@/components/Anime/AnimeEdit.vue";

export default defineComponent({
  name: "AnimeCard",
  components: {
    AnimeEdit,
  },
  props: {
    anime: {
      type: Anime,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAnimeDetail: false,
    };
  },
  computed: {
    detailStatus() {
      const status = this.anime?.agenda?.getStatusWatching();
      if (status === undefined) return "Aucune information";
      if (status <= 0) {
        if (!this.anime?.agenda?.getIsWeekUpdateAgenda()) {
          return `Aucune sortie prevue pour cette semaine`;
        }

        return `Anime ajour`;
      }
      return `${status} epsiode de retard`;
    },
    getEpisodeStatus(): string {
      const status = this.anime?.agenda?.getStatusWatching();
      if (status === undefined) return "bg-blue-500";

      if (status > 0) {
        return "bg-red-500";
      }

      if (!this.anime?.agenda?.getIsWeekUpdateAgenda()) {
        return "bg-purple-400";
      }

      return "bg-green-500";
    },
  },
  methods: {
    getUrlAnimeEpsiode(): string {
      const link =
        this.anime.user_animes?.getLinkEpisode() ??
        this.anime?.agenda?.getUrl();
      return link ?? this.anime.getMyanimelistUrl();
    },
    openAnimeDetail() {
      this.showAnimeDetail = !this.showAnimeDetail;
    },
  },
});
