import UserAuthManager from "@/class/login/UserAuthManager";
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import VueTippy from "vue-tippy";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./assets/css/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@vueform/multiselect/themes/default.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import MainEndpoint from "@/endpoints/main";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
library.add(faLink);
const app = createApp(App);
const pinia = createPinia();

app.use(store);
app.use(VueTippy);
app.use(router);
app.use(pinia);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

const code = new URLSearchParams(window.location.search).get("code");

router.push("Login").then(() => {
  UserAuthManager.tryRestoreSession(code);
  MainEndpoint.loadAxiosMiddleWare();
  UserAuthManager.startKeepAliveSession();
});
