
import { defineComponent } from "vue";
import MainEndpoint from "@/endpoints/main";
import UserAuthManager from "@/class/login/UserAuthManager";
import { mapState } from "pinia";
import UserAuthStore from "@/store/UserAuthStore";

export default defineComponent({
  name: "RedirectToken",
  computed: {
    ...mapState(UserAuthStore, ["user"]),
  },
  mounted() {
    const code = this.$route.query.code;
    if (!UserAuthManager.isLoggedIn()) {
      this.$router.push("/");
      return;
    }

    MainEndpoint.getAxiosInstance()
      .post("/auth/myanimelist/generate-token", {
        code,
      })
      .then((response) => {
        if (response.status === 200) {
          this.user?.setIsMalLinked(true);
          this.$router.push("/home");
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
