
import { defineComponent } from "vue";
import UserStore from "@/store/UserAuthStore";
import MainEndpoint from "@/endpoints/main";
import { mapState } from "pinia";
import UserAuthStore from "@/store/UserAuthStore";

export default defineComponent({
  name: "ProfileView",
  data() {
    return {
      linkMal: false,
      userStore: UserStore(),
    };
  },
  methods: {
    authorize() {
      MainEndpoint.getAxiosInstance()
        .get("/auth/myanimelist/get-url-auth")
        .then((response) => {
          const { authorizationUrl } = response.data;
          window.open(authorizationUrl, "_self");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    unlink() {
      this.user?.setIsMalLinked(false);
      this.linkMal = false;
    },
  },
  computed: {
    ...mapState(UserAuthStore, ["user"]),
  },
  mounted() {
    if (this.user?.isMalLink) {
      this.linkMal = this.user?.isMalLink;
    } else {
      this.linkMal = false;
    }
  },
});
