
import { defineComponent } from "vue";
import AnimeCard from "@/components/Agenda/AnimeAgendaCard.vue";
import DaysHelper from "@/helper/DaysHelper";
import moment from "moment";

export default defineComponent({
  name: "WeekCol",
  components: { AnimeCard },
  props: {
    day: {
      type: String,
      required: true,
    },
    animeList: {
      required: true,
    },
  },
  methods: {
    getDayOfWeek() {
      const currentDate = moment();
      return DaysHelper.getDayByIndex(currentDate.get("day"));
    },
  },
});
