
import { defineComponent } from "vue";
import UserAuthManager from "@/class/login/UserAuthManager";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    connect() {
      UserAuthManager.login(this.username, this.password);
    },
  },
});
