import UserAuthStore from "@/store/UserAuthStore";
import { AxiosError } from "axios";
import Endpoint from "@/endpoints/endpoint";
import UserAuthManager from "@/class/login/UserAuthManager";
import router from "@/router";
import Notiflix from "notiflix";

class MainEndPoint extends Endpoint {
  loadAxiosMiddleWare() {
    const userAuthStore = UserAuthStore();
    this.getAxiosInstance().interceptors.response.use(
      (r) => Promise.resolve(r),
      (error: AxiosError) => {
        if (error.response === undefined) {
          return Promise.reject(error);
        }
        if (
          error.response.status === 401 &&
          userAuthStore.token !== undefined
        ) {
          if (router.currentRoute.value.name !== "login") {
            Notiflix.Notify.info(
              "Votre session à expiré, merci de vous reconnecter"
            );
            UserAuthManager.logout(false);
          }
          return false;
        }
        return Promise.reject(error);
      }
    );
  }
}

const MainEndpoint = new MainEndPoint(process.env.VUE_APP_API_URL);
export default MainEndpoint;
