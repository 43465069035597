
import { defineComponent } from "vue";
import WeekCol from "@/components/Agenda/WeekCol.vue";
import { mapState } from "pinia";
import AnimeStore from "@/store/AnimeStore";
import Anime from "@/class/Anime";
import DaysHelper from "@/helper/DaysHelper";

export default defineComponent({
  name: "AgendaView",
  components: {
    WeekCol,
  },
  computed: {
    ...mapState(AnimeStore, {
      animeList: "getAnimeAganda",
    }),
    getDays(): string[] {
      return DaysHelper.getOrderDays();
    },
  },
  methods: {
    filteredAnimeList(day: string): Anime[] {
      return this.animeList
        .filter((anime) => {
          if (anime.agenda === undefined) return false;
          return anime.agenda.getDay() === day;
        })
        .sort((a, b) => {
          if (a.agenda === undefined || b.agenda === undefined) return 0;
          return a.agenda.getTime() > b.agenda.getTime() ? 1 : -1;
        });
    },
    refreshAnimeList() {
      AnimeStore().initAnimeWatching();
    },
  },
});
