
import { defineComponent } from "vue";
import UserAuthManager from "@/class/login/UserAuthManager";

export default defineComponent({
  name: "RegisterView",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      adminPassword: "",
    };
  },
  methods: {
    register() {
      UserAuthManager.register(
        this.username,
        this.password,
        this.adminPassword
      );
    },
  },
});
