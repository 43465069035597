class FilterAnime {
  private searchQuery = "";

  private tagsQuery: string[] = [];

  private statusQuery = "";

  private isOnReleaseAllow = true;

  private isStartWatching = false;

  public getSearchQuery(): string {
    return this.searchQuery;
  }

  public getTagsQuery(): string[] {
    return this.tagsQuery;
  }

  public getStatusQuery(): string {
    return this.statusQuery;
  }

  public getIsOnReleaseAllow(): boolean {
    return this.isOnReleaseAllow;
  }

  public getIsStartWatching(): boolean {
    return this.isStartWatching;
  }

  public setSearchQuery(value: string): void {
    this.searchQuery = value;
  }

  public setTagsQuery(value: string[]): void {
    this.tagsQuery = value;
  }

  public setStatusQuery(value: string): void {
    this.statusQuery = value;
  }

  public setIsOnReleaseAllow(value: boolean): void {
    this.isOnReleaseAllow = value;
  }

  public setIsStartWatching(value: boolean): void {
    this.isStartWatching = value;
  }

  public clearFilter(): void {
    this.searchQuery = "";
    this.tagsQuery = [];
    this.statusQuery = "";
    this.isOnReleaseAllow = true;
    this.isStartWatching = false;
  }
}

export default FilterAnime;
