import Axios, { AxiosInstance } from "axios";

class Endpoint {
  private readonly endpoint: string;

  private readonly axiosInstance: AxiosInstance;

  constructor(endpoint: string) {
    this.endpoint = endpoint;

    this.axiosInstance = Axios.create({
      baseURL: this.getEndpoint(),
      timeout: 60000 * 3, // 3 minutes
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  public getEndpoint(): string {
    return this.endpoint;
  }

  getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  public setBearerToken(token: string): void {
    this.axiosInstance.defaults.headers.common = {
      ...this.axiosInstance.defaults.headers.common,
      Authorization: `${token}`,
    };
  }
}
export default Endpoint;
