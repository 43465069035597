import moment from "moment";
import DaysHelper from "@/helper/DaysHelper";
import Anime from "@/class/Anime";
import Serializable from "@/helper/SerializationHelper";

class Agenda extends Serializable {
  public adkami_id = undefined as unknown as number;

  public last_episode_release = 0;

  public day = 0;

  public date = moment();

  public picture = "";

  public overdue = false;

  public finished = false;

  public nb_episode_start_season = 1;

  public season = 1;

  public is_week_release = true;

  public anime = undefined as Anime | undefined;

  // eslint-disable-next-line
  fillFromJSON(agendaJson: any) {
    super.fillFromJSON(agendaJson);
    this.date = moment(agendaJson.date);
  }

  constructor(anime: Anime) {
    super();
    this.anime = anime;
    this.adkami_id = anime.adkami_id;
  }

  public getIsWeekUpdateAgenda() {
    return this.is_week_release;
  }

  public getDay() {
    return DaysHelper.getDayByIndex(this.day);
  }

  public getTime(): string {
    const time = moment(this.date);
    return time.format("HH:mm");
  }

  public getUrl(): string {
    return `https://www.adkami.com/anime/${
      this.adkami_id
    }/${this.getNextEpisode()}/${this.anime?.season ?? 1}/2/1/`;
  }

  public getNextEpisode(): number {
    const lastEpisodeWatch = this.anime?.user_animes?.last_episode_watch;
    if (lastEpisodeWatch === undefined) return 1;

    if (lastEpisodeWatch >= this.last_episode_release) {
      return this.last_episode_release;
    }

    return lastEpisodeWatch + 1;
  }

  public getLastEpisodeRelease(): number {
    return this.last_episode_release - this.nb_episode_start_season;
  }

  public checkDatePassed(): boolean {
    const time = moment(this.date);
    const now = moment();

    return time.isAfter(now);
  }

  public getStatusWatching(): number {
    const lastWatchedEpisode = this.anime?.user_animes?.last_episode_watch;
    if (lastWatchedEpisode === undefined) return 0;

    this.getLastEpisodeRelease();
    let lastEpisode = this.last_episode_release - this.nb_episode_start_season;

    if (this.checkDatePassed()) {
      lastEpisode -= 1;
    }

    return lastEpisode - lastWatchedEpisode;
  }

  public getIsFinishAiring(): boolean {
    return true;
  }
}

export default Agenda;
