
import { defineComponent } from "vue";
import Anime from "@/class/Anime";
import AnimeEdit from "@/components/Anime/AnimeEdit.vue";

export default defineComponent({
  name: "AnimeListCard",
  components: { AnimeEdit },
  props: {
    anime: {
      type: Anime,
      required: true,
    },
  },
  data() {
    return {
      showAnimeDetail: false,
    };
  },
  methods: {
    getUrlAnimeEpsiode(): string {
      const link =
        this.anime.user_animes?.getLinkEpisode() ??
        this.anime?.agenda?.getUrl();
      return link ?? this.anime.getMyanimelistUrl();
    },
  },
});
