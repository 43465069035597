import Agenda from "@/class/Agenda";
import User_anime from "@/class/User_anime";
import Serializable from "@/helper/SerializationHelper";
import MainEndpoint from "@/endpoints/main";

class Anime extends Serializable {
  public title = "";

  public myanimelist_id = undefined as unknown as number;

  public adkami_id = undefined as unknown as number;

  public total_episode = 0;

  public picture = "";

  public status = "";

  public season = 0;

  public agenda = undefined as Agenda | undefined;

  public user_animes = undefined as User_anime | undefined;

  // eslint-disable-next-line
  fillFromJSON(animeJson: any) {
    super.fillFromJSON(animeJson);

    if (animeJson.agenda === null) {
      this.agenda = undefined;
    } else {
      const agenda = new Agenda(this);
      agenda.fillFromJSON(animeJson.agenda);
      this.agenda = agenda;
    }

    if (animeJson.user_animes === undefined) {
      this.user_animes = undefined;
    } else {
      const userAnime = new User_anime(this);
      userAnime.fillFromJSON(animeJson.user_animes[0]);
      this.user_animes = userAnime;
    }
  }

  public getUserAnime() {
    return this.user_animes;
  }

  public setLastEpisodeWatch(last_episode_watch: number) {
    if (this.user_animes === undefined) {
      return;
    }

    this.user_animes.last_episode_watch = last_episode_watch;
  }

  public getMyanimelistUrl(): string {
    return `https://myanimelist.net/anime/${this.myanimelist_id}`;
  }

  public getIsAgenda() {
    return this.agenda !== undefined;
  }

  public updateAnimeAdmin() {
    MainEndpoint.getAxiosInstance()
      .post("admin/agenda/update", {
        anime_myanimelist_id: this.myanimelist_id,
        adkami_id: this.adkami_id,
        last_episode_release: this.agenda?.last_episode_release,
        finished: this.agenda?.finished,
        overdue: this.agenda?.overdue,
        season: this.season,
        nb_episode_start_season: this.agenda?.nb_episode_start_season,
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public addAdkamiId() {
    const data = {
      anime_myanimelist_id: this.myanimelist_id,
      adkamiId: this.adkami_id,
    };

    MainEndpoint.getAxiosInstance()
      .post("/admin/agenda/link/update", data)
      .then((response) => {
        if (response.status === 200) {
          this.fillFromJSON(response.data);
        }
      })
      .catch((error) => {
        this.adkami_id = undefined as unknown as number;
        console.log(error);
      });
  }
}

export default Anime;
