
import { defineComponent } from "vue";
import AnimeStore from "@/store/AnimeStore";
import { mapState } from "pinia";
import Anime from "@/class/Anime";

export default defineComponent({
  name: "DashBordView",
  data() {
    return {
      searchQuery: "",
      isStartWatching: false,
      isOnReleasingAllow: true,
      isSorted: false,
    };
  },
  computed: {
    ...mapState(AnimeStore, {
      animeList: "getAnimeAdmin",
      filter: "getFilter",
    }),
    getAnimeAdmin(): Anime[] {
      const animeAdminList = this.animeList;
      return animeAdminList.sort((a, b) => {
        if (this.isSorted) {
          if (a.getIsAgenda()) {
            return 1;
          }
          if (b.getIsAgenda()) {
            return -1;
          }
        }
        return a.title.localeCompare(b.title);
      });
    },
  },
  watch: {
    searchQuery(newVal) {
      this.filter.setSearchQuery(newVal);
    },

    "filter.searchQuery": {
      handler(newVal) {
        this.searchQuery = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    toggleSort() {
      this.isSorted = !this.isSorted;
    },
    toggleReleasingAllow() {
      this.isOnReleasingAllow = !this.isOnReleasingAllow;
      this.filter.setIsOnReleaseAllow(this.isOnReleasingAllow);
    },
    toggleStartWatching() {
      this.isStartWatching = !this.isStartWatching;
      this.filter.setIsStartWatching(this.isStartWatching);
    },
    clearFilter() {
      this.filter.clearFilter();
      this.searchQuery = "";
      this.isOnReleasingAllow = true;
      this.isStartWatching = false;
    },
  },
});
