
import { defineComponent } from "vue";
import { mapState } from "pinia";
import UserAuthStore from "@/store/UserAuthStore";
import UserAuthManager from "@/class/login/UserAuthManager";

export default defineComponent({
  name: "NavBar",
  data() {
    return {
      isDropdownVisible: false,
    };
  },
  components: {},
  methods: {
    logout() {
      UserAuthManager.logout();
    },
    capitalizeFirstLetter(str: string | null): string | null {
      if (!str) return null;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    showDropdown() {
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
  },
  computed: {
    ...mapState(UserAuthStore, ["user"]),
  },
});
