
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    showNavbar(): boolean {
      return !this.$route.meta.hideNavbar;
    },
  },
  methods: {},
});
