
import { defineComponent } from "vue";
import { mapState } from "pinia";
import AnimeStore from "@/store/AnimeStore";
import AnimeListCard from "@/components/AnimeList/AnimeListCard.vue";
import StatusMyanimelist from "@/helper/StatusMyanimelist";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "AnimeListView",
  components: {
    AnimeListCard,
    Multiselect,
  },
  data() {
    return {
      searchQuery: "",
      selectedTags: [] as string[],
      isOnReleasingAllow: true,
      isStartWatching: false,
      selectedStatus: "watching",
    };
  },
  computed: {
    ...mapState(AnimeStore, {
      animeFiltred: "getFilteredAnimeList",
      animeList: "getWatchingAnime",
      filter: "getFilter",
    }),
    getStatusAnime() {
      return StatusMyanimelist.getAllUserStatus();
    },
  },
  watch: {
    searchQuery(newVal) {
      this.filter.setSearchQuery(newVal);
    },

    "filter.searchQuery": {
      handler(newVal) {
        this.searchQuery = newVal;
      },
      immediate: true,
    },

    selectedTags(newVal: string[]) {
      this.filter.setTagsQuery(newVal);
    },

    "filter.tagsQuery": {
      handler(newVal) {
        this.selectedTags = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    removeTags(tag: string) {
      this.selectedTags = this.selectedTags.filter((t) => t !== tag);
      this.filter.setTagsQuery(this.selectedTags);
    },
    toggleReleasingAllow() {
      this.isOnReleasingAllow = !this.isOnReleasingAllow;
      this.filter.setIsOnReleaseAllow(this.isOnReleasingAllow);
    },
    toggleStartWatching() {
      this.isStartWatching = !this.isStartWatching;
      this.filter.setIsStartWatching(this.isStartWatching);
    },
    setStatus() {
      this.filter.setStatusQuery(this.selectedStatus);
    },
    clearFilter() {
      this.filter.clearFilter();
      this.searchQuery = "";
      this.isOnReleasingAllow = true;
      this.isStartWatching = false;
      this.selectedTags = [];
      this.selectedStatus = "watching";
    },
  },
});
