
import { defineComponent } from "vue";
import AnimeStore from "@/store/AnimeStore";
import { mapState } from "pinia";
import UserAuthManager from "@/class/login/UserAuthManager";
import UserAuthStore from "@/store/UserAuthStore";

export default defineComponent({
  name: "HomePage",
  computed: {
    ...mapState(UserAuthStore, ["user"]),
    ...mapState(AnimeStore, {
      animeList: "getWatchingAnime",
      animeAgenda: "getAnimeAganda",
    }),
  },
  mounted() {
    if (!UserAuthManager.isLoggedIn()) {
      this.$router.push({ path: "/" });
    }

    if (this.user?.isMalLink) {
      AnimeStore().initAnime();
    }
  },
});
