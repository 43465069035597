import { defineStore } from "pinia";
import User from "@/class/login/User";

const UserAuthStore = defineStore("userauth", {
  state: () => ({
    user: <User | undefined>undefined,
    token: <string | undefined>undefined,
  }),
});

export default UserAuthStore;
