import Notiflix from "notiflix";
import { AxiosError, AxiosResponse } from "axios";
import MainEndpoint from "@/endpoints/main";
import router from "@/router";
import UserAuthStore from "@/store/UserAuthStore";
import AnimeStore from "@/store/AnimeStore";
import User from "@/class/login/User";

class UserAuthManager {
  public static isLoggedIn(): boolean {
    const userAuthStore = UserAuthStore();
    return (
      userAuthStore.user !== undefined && userAuthStore.token !== undefined
    );
  }

  public static startKeepAliveSession() {
    const userAuthStore = UserAuthStore();

    setInterval(() => {
      if (UserAuthManager.isLoggedIn()) {
        MainEndpoint.getAxiosInstance()
          .get("/auth/keep-alive")
          .then((response) => {
            userAuthStore.token = response.data.token;
            localStorage.setItem("token", response.data.token);
            MainEndpoint.setBearerToken(response.data.token);
          })
          .catch(() => {
            this.handleLogout(false);
            router.push("/");
          });
      }
    }, 1000 * 60 * 10); // Toutes les 5 minutes
  }

  public static tryRestoreSession(code: string | null): void {
    const userAuthStore = UserAuthStore();

    const userJSON = localStorage.getItem("userKumagenda");
    const token = localStorage.getItem("tokenKumagenda");
    if (token !== null && userJSON !== null) {
      const user = new User();
      user.fillFromJSONString(userJSON as string);

      MainEndpoint.setBearerToken(token as string);

      MainEndpoint.getAxiosInstance()
        .get("/auth/keep-alive")
        .then(() => {
          const lastRoute = localStorage.getItem("lastRouteKumagenda");
          if (lastRoute === null) {
            return;
          }
          userAuthStore.user = user;
          userAuthStore.token = token as string;
          if (code !== null) {
            router.push("/redirect-token?code=" + code);
          } else {
            router.push("/home");
          }
          Notiflix.Notify.success("Votre session vient d'être restauré !");
        })
        .catch(() => {
          this.handleLogout(false);
          router.push("/");
        });
    }
  }

  public static login(username: string, password: string): void {
    const userAuthStore = UserAuthStore();
    Notiflix.Loading.circle("Connexion en cours", { svgColor: "white" });
    MainEndpoint.getAxiosInstance()
      .post("/auth/login", {
        username,
        password,
      })
      .then((response: AxiosResponse) => {
        userAuthStore.token = response.data.token;
        const user = new User();
        user.fillFromJSON(response.data);
        userAuthStore.user = user;
        // Authorize future request by providing valid bearer token
        MainEndpoint.setBearerToken(response.data.token);
        // Store token and user inside the navigator
        localStorage.setItem("userKumagenda", user.toJson());
        localStorage.setItem("tokenKumagenda", response.data.token);
        // Redirect to the good route from his role
        router.push("/home");
      })
      .catch((error: AxiosError) => {
        console.log(error);
        if (error.response === undefined) {
          Notiflix.Notify.failure("Impossible de se connecter au serveur !");
          return;
        }
        switch (error.response.status) {
          case 401:
            Notiflix.Notify.failure("Identifiants incorrects !");
            break;
          case 500:
            Notiflix.Notify.failure("Erreur interne serveur !");
            break;
          default:
            Notiflix.Notify.failure(
              "Impossible de se connecter, erreur inconnue !"
            );
            break;
        }
      })
      .finally(() => Notiflix.Loading.remove());
  }

  public static handleLogout(logout = true): void {
    const userAuthStore = UserAuthStore();
    userAuthStore.token = undefined;
    userAuthStore.user = undefined;
    AnimeStore().clearStore();
    localStorage.removeItem("userKumagenda");
    localStorage.removeItem("lastRouteKumagenda");
    localStorage.removeItem("tokenKumagenda");
    if (logout) {
      Notiflix.Notify.success("Vous venez de vous déconnecter");
    } else {
      Notiflix.Notify.failure("Votre session a expiré !");
    }
  }

  public static logout(showConfirm = true): void {
    if (showConfirm) {
      Notiflix.Confirm.show(
        "Déconnexion",
        "Voulez-vous vraiment vous déconnecter ?",
        "Oui",
        "Non",
        () => {
          router.push("logout");
        },
        undefined,
        {
          titleColor: "red",
          okButtonBackground: "rgb(255,50,50)",
          width: "400px",
        }
      );
    } else {
      router.push("logout");
    }
  }

  public static register(
    username: string,
    password: string,
    adminPassword: string
  ): void {
    if (username === "" || password === "" || adminPassword === "") {
      Notiflix.Notify.failure("Veuillez remplir tous les champs !");
      return;
    }
    const data = {
      username,
      password,
      admin_password: adminPassword,
    };

    MainEndpoint.getAxiosInstance()
      .post("/auth/register", data)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          Notiflix.Notify.success("Inscription réussie !");
          router.push("login");
        }
      })
      .catch((error: AxiosError) => {
        if (error.response === undefined) {
          Notiflix.Notify.failure("Impossible de se connecter au serveur !");
          return;
        }
        switch (error.response.status) {
          case 401:
            Notiflix.Notify.failure("Username utiliser");
            break;
          case 403:
            Notiflix.Notify.failure("Mot de passe admin incorrect");
            break;
          case 500:
            Notiflix.Notify.failure("Erreur interne serveur !");
            break;
          default:
            Notiflix.Notify.failure(
              "Impossible de s'inscrire, erreur inconnue !"
            );
            break;
        }
      });
  }
}

export default UserAuthManager;
