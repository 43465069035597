import Serializable from "@/helper/SerializationHelper";

class User extends Serializable {
  public username = "";

  public role = "";

  public picture = "";

  public isMalLink = false;

  // eslint-disable-next-line
  public fillFromJSON(data: any) {
    this.username = data.username;
    this.role = data.role;
    this.picture = data.picture;
    this.isMalLink = data.isMalLink;
  }

  public setIsMalLinked(isMalToken: boolean) {
    this.isMalLink = isMalToken;
  }
}

export default User;
